import {
    IEnrichedCategory,
    IEnrichedMenuWithModifierMaps,
    IEnrichedProduct,
    isProductGroup
} from 'components/menu/model/Menu';
import { IOrderCreatePaymentV8, OrderScenario } from 'components/order/model/Order';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import {
    isArrayOf,
    isBoolean,
    isDefined,
    isNumber,
    isOptional,
    isOptionalArrayOf,
    isOptionalString,
    isString
} from 'lib/typeInference';
import {
    IOrderPaymentReadResource,
    IAdjustmentReadResourceV10 as IAdjustmentResource
} from '../../order/model/Order';

export interface IBasketRequestResource {
    scenario: OrderScenario;
    locationId: string;
    items: IBasketItem[];
    adjustments?: IBasketAdjustment[];
    deliveryLocation?: string;
    payments?: IOrderCreatePaymentV8[];
    timeSlot?: Timeslot;
}

export interface IBasketItemV10 {
    productId: string;
    categoryId: string;
    quantity: number;
    modifiers?: IBasketItemModifier[];
    referenceId?: string;
    parentReferenceId?: string;
    note?: string;
}

export interface IBasketAdjustment {
    awardId?: string;
    quantity?: number;
    value?: number;
    type?: OrderAdjustmentType;
    isPercentage?: boolean;
}

export interface IBasketItem extends IBasketItemV10 {
    adjustments?: IBasketAdjustment[];
}

export interface IBasketItemModifier {
    id: string;
    options: IBasketItemModifierOption[];
}

export interface IBasketItemModifierOption {
    id: string;
    name?: string;
    cost?: number;
}

// ------------------------------------------------------------------
// V10 RESPONSE Resource Types
// ------------------------------------------------------------------

export type CurrencyCode = 'USD' | string;
// use common resources in V10
export interface IBasketResourceV10 {
    locationId: string;
    currencyCode: CurrencyCode;
    scenario: OrderScenario;
    items: IItemReadResourceV10[];
    adjustments: IAdjustmentResource[];
    availableAdjustments: IAdjustmentResource[];
    total: IBasketTotal;
    payments?: IOrderPaymentReadResource[];
}

export interface IItemModifierOptionResourceV10 {
    id: string;
    name: string;
    cost: number;
    title?: string;
    price?: number;
}

function isItemModifierOptionResourceV10(input: any): input is IItemModifierOptionResourceV10 {
    return isString(input.id) && isString(input.name) && isNumber(input.cost);
}

export interface IItemModifierResourceV10 {
    id: string;
    name?: string;
    title?: string;
    options: IItemModifierOptionResourceV10[];
}

function isItemModifierResourceV10(input: any): input is IItemModifierOptionResourceV10 {
    return (
        isString(input.id) &&
        isString(input.name) &&
        isArrayOf(isItemModifierOptionResourceV10, input.options)
    );
}

export enum OrderAdjustmentType {
    DISCOUNT = 'DISCOUNT',
    TIP = 'TIP',
    CHARGE = 'CHARGE',
    REFUND = 'REFUND'
}

export interface IAdjustmentReadResourceV10 {
    id?: number;
    userId?: string;
    awardId?: string;
    parentId?: number;
    paymentId?: number;
    pointsRedeemed?: number;
    description?: string;
    isAllocation?: boolean;
    quantity?: number;
    type?: OrderAdjustmentType;
    value: number;
    deleted?: boolean;
    isPercentage?: boolean;
    taxToAdd?: number;
}

function isAdjustmentReadResourceV10(input: any): input is IAdjustmentReadResourceV10 {
    return (
        isNumber(input.id) &&
        isString(input.userId) &&
        isOptionalString(input.awardId) &&
        isOptional(isNumber, input.parentId) &&
        isOptional(isNumber, input.paymentId) &&
        isOptional(isNumber, input.pointsRedeemed) &&
        isOptionalString(input.description) &&
        isBoolean(input.isAllocation) &&
        isNumber(input.quantity) &&
        input.type in OrderAdjustmentType &&
        isNumber(input.value) &&
        isOptional(isBoolean, input.deleted)
    );
}

export interface IItemReadResourceV10 {
    id: number;
    userId: string;
    categoryId: string;
    categoryName: string;
    productId: string;
    productName: string;
    modifiers?: IItemModifierResourceV10[];
    adjustments?: IAdjustmentReadResourceV10[];
    cost: number;
    quantity: number;
    deleted?: boolean;
    referenceId?: string;
    parentReferenceId?: string;
    note?: string;
    // Computed at runtime
    isClaimedBy?: string[];
}

export function isItemReadResourceV10(input: any): input is IItemReadResourceV10 {
    return (
        isNumber(input.id) &&
        isString(input.userId) &&
        isString(input.categoryId) &&
        isString(input.categoryName) &&
        isString(input.productId) &&
        isString(input.productName) &&
        isOptionalArrayOf(isItemModifierResourceV10, input.modifiers) &&
        isOptionalArrayOf(isAdjustmentReadResourceV10, input.adjustments) &&
        isNumber(input.cost) &&
        isNumber(input.quantity) &&
        isOptional(isBoolean, input.deleted) &&
        isOptionalString(input.referenceId) &&
        isOptionalString(input.parentReferenceId)
    );
}

export interface ITaxLine {
    name: string;
    id: string;
    amount: number;
    inclusive: boolean; // true if tax is included in the item cost
    rate: number; // tax rate as a fraction 0..1
}

function isTaxLine(input: any): input is ITaxLine {
    return (
        isString(input.name) &&
        isString(input.id) &&
        isNumber(input.amount) &&
        isBoolean(input.inclusive) &&
        isNumber(input.rate)
    );
}

export interface IBasketTotal {
    cost: number;
    charges: number;
    discounts: number;
    taxes: ITaxLine[];
    total: number; // total including exclusive tax.  this is the payment amount excluding tips
    balance: number;
    payments: number;
}

export function isBasketTotal(input: any): input is IBasketTotal {
    return (
        isNumber(input.cost) &&
        isNumber(input.charges) &&
        isNumber(input.discounts) &&
        isArrayOf(isTaxLine, input.taxes) &&
        isNumber(input.total)
    );
}

export function isBasketCalculatorData(input: any): input is IBasketResourceV10 {
    return (
        isString(input.locationId) &&
        isString(input.currencyCode) &&
        input.scenario in OrderScenario &&
        isArrayOf(isItemReadResourceV10, input.items) &&
        isArrayOf(isAdjustmentReadResourceV10, input.adjustments) &&
        isArrayOf(isAdjustmentReadResourceV10, input.availableAdjustments) &&
        isBasketTotal(input.total)
    );
}

// Item option modifier validation function
export function isOptionModifiersValid(menu: IEnrichedMenuWithModifierMaps, categoryId: string) {
    return (item: IBasketItemModifier) => {
        const modifier = menu.modifierIdToCategoryOptionModifierSettings.get(categoryId)?.get(item.id);
        if (!modifier) {
            return false;
        }
        return item.options.every(option =>
            modifier.options.some(
                currentOption =>
                    currentOption.id === option.id &&
                    (currentOption.available || !isDefined(currentOption.available))
            )
        );
    };
}

// Item product modifier validation function
export function isProductModifierValid(
    item: IBasketItem | IItemReadResourceV10,
    products: IEnrichedProduct[],
    items: IBasketItem[] | IItemReadResourceV10[]
) {
    let productId: string | undefined;
    if (item.parentReferenceId) {
        productId = items.find(basketItem => basketItem.referenceId === item.parentReferenceId)?.productId;
    }
    if (item.parentReferenceId && !productId) {
        return false;
    }
    const relatedProduct = products.find(product => product.id === productId);
    if (relatedProduct && isDefined(relatedProduct.available) && !relatedProduct.available) {
        return false;
    }
    return true;
}

function isBasketItemValid(
    products: IEnrichedProduct[],
    items: IBasketItem[],
    menu: IEnrichedMenuWithModifierMaps
) {
    return (item: IBasketItem) => {
        let product;
        if (item.categoryId) {
            const category = menu.categories.find(category => category.id === item.categoryId);
            product = category?.products?.find(product => product.id === item.productId);
        }
        if (!product || (isDefined(product.available) && !product.available)) {
            return false;
        }
        const productModifierValid = isProductModifierValid(item, products, items);
        const optionsModifierValid =
            !item.modifiers ||
            !item.modifiers.length ||
            item.modifiers.every(isOptionModifiersValid(menu, item.categoryId));
        return productModifierValid && optionsModifierValid;
    };
}

export function filterBasket(
    categories: IEnrichedCategory[],
    items: IBasketItem[],
    menu?: IEnrichedMenuWithModifierMaps
) {
    if (!menu) {
        return { items, isValid: true };
    }
    const products = categories.reduce<IEnrichedProduct[]>((acc, item) => {
        item.products.forEach(product => {
            if (isProductGroup(product)) {
                product.products.forEach(innerProduct => {
                    acc.push(innerProduct);
                });
            } else {
                acc.push(product);
            }
        });
        return acc;
    }, []);
    const newItems = items.filter(isBasketItemValid(products, items, menu));
    const isValid = newItems.length === items.length;
    return { isValid, items: isValid ? items : newItems };
}
