import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { checkAvailableAsset } from 'lib/helpers';
import { isDefined } from 'lib/typeInference';

interface IProps {
    backgroundImg: string;
    defaultImage: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        journeySelectionWrapper: {
            paddingTop: `${(150 / 388) * 100}%`,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            position: 'relative'
        },
        journeyImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    })
);

export const ScenarioCard: React.FC<IProps> = ({ backgroundImg, onClick, defaultImage }) => {
    const [hasImg, setHasImg] = React.useState<boolean>();
    const classes = useStyles();

    React.useEffect(() => {
        checkAvailableAsset([backgroundImg])
            .then(() => setHasImg(true))
            .catch(() => setHasImg(false));
    }, [backgroundImg]);

    return (
        <div className={classes.journeySelectionWrapper} onClick={onClick}>
            {!isDefined(hasImg) ? (
                <Skeleton
                    className={classes.journeyImage}
                    variant="rect"
                    animation="wave"
                    style={{ zIndex: 100 }}
                />
            ) : (
                <img src={hasImg === false ? defaultImage : backgroundImg} className={classes.journeyImage} />
            )}
        </div>
    );
};
