import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, createStyles, Grid, Link, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OrderToTableLocationPicker } from 'components/location/OrderToTableLocationPicker';
import { OrderScenario } from 'components/order/model/Order';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { addOpacity, checkAvailableAsset } from 'lib/helpers';
import logger from 'lib/logger';
import { MuiMap } from 'lib/MuiMap';
import { isDefined } from 'lib/typeInference';
import { LocationRouteParams } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { ScenarioCard } from './ScenarioCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        map: {
            width: '100%',
            height: '150px',
            flexShrink: 0
        },

        locationIcon: {
            marginRight: theme.spacing(0.5)
        },
        locationTitle: {
            fontWeight: theme.typography.fontWeightBold
        },
        phone: {
            color: theme.palette.text.primary
        },
        scenarioBackgorund: {
            backgroundColor: addOpacity(theme.palette.background.default, 0.8),
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(2)
        },
        landingDetails: {
            overflow: 'hidden'
        }
    })
);

interface IProps {
    isLocationRetired: boolean;
    handleViewMenu: () => void;
    handleScenarioChange: (scenario: OrderScenario) => () => void;
    menuLink: string | undefined;
}

export const LandingDesktop: React.FC<IProps> = ({
    isLocationRetired,
    handleViewMenu,
    handleScenarioChange,
    menuLink
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { locationId, merchantId } = useParams<LocationRouteParams>();
    const [merchantLogo, setMerchantLogo] = React.useState<string>();

    const settings = useSelector((state: ApplicationState) => state.settings);
    const { currentLocation: location } = useLocationHelpers();

    React.useEffect(() => {
        if (merchantId) {
            checkAvailableAsset([
                `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_icon.svg`,
                `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_icon.png`
            ])
                .then((url: any) => {
                    setMerchantLogo(url);
                })
                .catch(err => {
                    logger.error(err);
                });
        }
    }, [merchantId]);

    const renderMerchantTitle = React.useMemo(() => {
        if (merchantLogo) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={merchantLogo} height="26px" />
                </Box>
            );
        }
        return settings.settings?.title;
    }, [merchantLogo, settings.settings?.title]);

    const locationGeo = React.useMemo(() => {
        if (location) {
            return {
                lat: location?.geo[1],
                lng: location?.geo[0],
                markerName: location?.title
            };
        }
        return null;
    }, [location]);
    const distanceUnit = useSelector(
        (state: ApplicationState) => state.settings.settings?.region.distanceUnit
    );

    let distance: number | undefined = location?.distance || 0;
    if (distanceUnit === 'mi') {
        distance = isDefined(distance) ? distance * 0.621371 : undefined;
    }
    const addressLine = React.useMemo(
        () =>
            !!location
                ? [
                      location.address.address,
                      location.address.town,
                      location.address.country,
                      location.address.postcode
                  ]
                      .filter(item => !!item)
                      .join(', ')
                : '',
        [location]
    );

    return (
        <>
            <MuiAppBar title={renderMerchantTitle} disableBack />
            <Box marginTop={1.5}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <OrderToTableLocationPicker
                            disableBottomSpace
                            pickerTitle="location"
                            locationId={locationId}
                        />
                        <Box marginTop={2}>
                            <Paper className={classes.landingDetails}>
                                {locationGeo && (
                                    <MuiMap
                                        width={0}
                                        center={locationGeo}
                                        options={{
                                            keyboardShortcuts: false,
                                            disableDefaultUI: true,
                                            zoomControl: true
                                        }}
                                        markers={[locationGeo]}
                                        className={classes.map}
                                        useCustomPin
                                        zoom={15}
                                    />
                                )}
                                <Box padding={2}>
                                    <div>
                                        <Typography className={classes.locationTitle} variant="h6">
                                            {location?.title}
                                        </Typography>
                                        <Typography variant="body2">{location?.address.summary}</Typography>
                                        {!!distance && (
                                            <Typography variant="caption">
                                                <LocationOn
                                                    className={classes.locationIcon}
                                                    fontSize="inherit"
                                                />
                                                {`${Math.floor(distance).toLocaleString()}${
                                                    distanceUnit || 'km'
                                                }`}
                                            </Typography>
                                        )}
                                    </div>
                                    <Box marginTop={1}>
                                        <Typography>
                                            <Trans
                                                i18nKey="LOCATION_ADDRESS"
                                                components={[<b key="address" />]}
                                                values={{ address: addressLine }}
                                            />
                                        </Typography>
                                    </Box>
                                    <Box marginTop={1}>
                                        {location?.contacts.phone && (
                                            <Link
                                                className={classes.phone}
                                                href={`tel:${location.contacts.phone}`}
                                            >
                                                <Typography>
                                                    <b>
                                                        {t('LOCATION_CONTACT_PHONE', {
                                                            phone: location.contacts.phone
                                                        })}
                                                    </b>
                                                </Typography>
                                            </Link>
                                        )}
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box className={classes.scenarioBackgorund}>
                            <Grid container spacing={2} justify="center">
                                {location?.services.order_to_table && !isLocationRetired && (
                                    <Grid item md={12}>
                                        <ScenarioCard
                                            defaultImage={`${process.env.PUBLIC_URL}/images/defaults/journey/OTT.png`}
                                            backgroundImg={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_ott_selection.png`}
                                            onClick={handleScenarioChange(OrderScenario.ORDER_TO_TABLE)}
                                        />
                                    </Grid>
                                )}
                                {location?.services.pay_at_table && !isLocationRetired && (
                                    <Grid item md={12}>
                                        <ScenarioCard
                                            defaultImage={`${process.env.PUBLIC_URL}/images/defaults/journey/pay_my_bill.png`}
                                            backgroundImg={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_pat_selection.png`}
                                            onClick={handleScenarioChange(OrderScenario.TABLE)}
                                        />
                                    </Grid>
                                )}
                                {!!menuLink && (
                                    <Grid item md={12}>
                                        <ScenarioCard
                                            defaultImage={`${process.env.PUBLIC_URL}/images/defaults/journey/view_menu.png`}
                                            backgroundImg={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_menu_selection.png`}
                                            onClick={handleViewMenu}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
