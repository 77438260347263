import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { makeRouteScenario } from 'src/utils/route';
import { getLocalBasket } from 'components/basket/localStore';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { LocationState } from 'components/location/model/Location';
import { OrderToTableLocationPicker } from 'components/location/OrderToTableLocationPicker';
import { OrderScenario } from 'components/order/model/Order';
import { getShouldUpdateGuestSession, resetShouldUpdateGuestSession } from 'components/user/localAuth';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { checkAvailableAsset } from 'lib/helpers';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import logger from 'lib/logger';
import { ResponsiveLayout } from 'lib/Responsive';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { createGuestSession } from 'store/auth/authActions';
import { resetBasket, setBasket } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { LandingDesktop } from './LandingDesktop';
import { ScenarioCard } from './ScenarioCard';

export const JourneyLandingPage: React.FC = () => {
    const dispatch = useDispatch();
    const { push } = useLocalHistory();
    const { locationId, merchantId } = useParams<LocationRouteParams>();
    const [merchantLogo, setMerchantLogo] = React.useState<string>();

    const { deliveryLocation } = useSelector((state: ApplicationState) => state.basket);
    const settings = useSelector((state: ApplicationState) => state.settings);
    const { currentLocation } = useLocationHelpers();
    const { isGuest } = useAuth();

    React.useEffect(() => {
        if (merchantId) {
            checkAvailableAsset([
                `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_icon.svg`,
                `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_icon.png`
            ])
                .then((url: any) => {
                    setMerchantLogo(url);
                })
                .catch(err => {
                    logger.error(err);
                });
        }
    }, [merchantId]);

    React.useEffect(() => {
        if (isGuest) {
            if (merchantId) {
                const shouldUpdateGuestSession = getShouldUpdateGuestSession(merchantId);
                if (shouldUpdateGuestSession) {
                    createGuestSession(dispatch).then(() => {
                        resetShouldUpdateGuestSession(merchantId);
                    });
                }
            }
        }
    }, [dispatch, isGuest, merchantId]);

    React.useEffect(() => {
        const localBasket = getLocalBasket();
        if (localBasket && localBasket.locationId === locationId) {
            setBasket({ deliveryLocation: localBasket.deliveryLocation, locationId })(dispatch);
        } else {
            resetBasket(dispatch);
        }
    }, [deliveryLocation, dispatch, locationId]);

    const handleScenarioChange = React.useCallback(
        (scenario: OrderScenario) => () => {
            setBasket({ locationId, deliveryLocation })(dispatch);
            switch (scenario) {
                case OrderScenario.ORDER_TO_TABLE:
                    if (merchantId) {
                        push(ROUTES.MENU, {
                            merchantId,
                            locationId: locationId || '',
                            scenario: makeRouteScenario(scenario)
                        });
                    }
                    break;
                case OrderScenario.TABLE:
                    if (merchantId) {
                        push(ROUTES.JOURNEY.PAT.BILL, {
                            merchantId,
                            locationId: locationId || '',
                            scenario: makeRouteScenario(scenario)
                        });
                    }
                    break;
                default:
                    break;
            }
        },
        [deliveryLocation, dispatch, locationId, merchantId, push]
    );
    const menuLink = React.useMemo(
        () => settings.settings?.payAtTable?.noTableCheckFoundUrl,
        [settings.settings?.payAtTable?.noTableCheckFoundUrl]
    );
    const handleViewMenu = React.useCallback(() => window.open(menuLink, '_blank'), [menuLink]);
    const renderMerchantTitle = React.useMemo(() => {
        if (merchantLogo) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={merchantLogo} height="26px" />
                </Box>
            );
        }
        return settings.settings?.title;
    }, [merchantLogo, settings.settings?.title]);

    const isLocationRetired = React.useMemo(
        () => !!currentLocation && currentLocation.state === LocationState.RETIRED,
        [currentLocation]
    );

    return (
        <ResponsiveLayout
            renderMobileView={
                <InnerPageLayout disableContentSpaces>
                    <MuiAppBar title={renderMerchantTitle} disableBack />
                    <OrderToTableLocationPicker
                        disableBottomSpace
                        pickerTitle="location"
                        locationId={locationId}
                    />
                    <InnerPageLayoutContent>
                        <Box paddingBottom={2} marginTop={2} textAlign="center">
                            <Grid container spacing={2} justify="center">
                                {currentLocation?.services.order_to_table && !isLocationRetired && (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <ScenarioCard
                                            defaultImage={`${process.env.PUBLIC_URL}/images/defaults/journey/OTT.png`}
                                            backgroundImg={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_ott_selection.png`}
                                            onClick={handleScenarioChange(OrderScenario.ORDER_TO_TABLE)}
                                        />
                                    </Grid>
                                )}
                                {currentLocation?.services.pay_at_table && !isLocationRetired && (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <ScenarioCard
                                            defaultImage={`${process.env.PUBLIC_URL}/images/defaults/journey/pay_my_bill.png`}
                                            backgroundImg={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_pat_selection.png`}
                                            onClick={handleScenarioChange(OrderScenario.TABLE)}
                                        />
                                    </Grid>
                                )}
                                {!!menuLink && (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <ScenarioCard
                                            defaultImage={`${process.env.PUBLIC_URL}/images/defaults/journey/view_menu.png`}
                                            backgroundImg={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_menu_selection.png`}
                                            onClick={handleViewMenu}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </InnerPageLayoutContent>
                </InnerPageLayout>
            }
            renderDesktopView={
                <LandingDesktop
                    menuLink={menuLink}
                    isLocationRetired={isLocationRetired}
                    handleViewMenu={handleViewMenu}
                    handleScenarioChange={handleScenarioChange}
                />
            }
        />
    );
};
